import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes as DomRoutes, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { mobile } from "../utilities/detection";
import Card from "../components/redeem/card";
import Redeem from "../components/redeem";
import Giftcard from "../components/giftcard";
import Testimonals from "../components/testimonials";
import Team from "../components/team";
import Story from "../components/story";
import About from "../components/about";
import Partea from "../components/partea";
import BlogPost from "../components/blog/blogPost";
import Blog from "../components/blog";
import Search from "../components/search";
import Games from "../components/games";
import Game from "../components/games/game";
import MobileGame from "../components/games/mobileGame";
import GameProxy from "../components/games/gameProxy";
import CategoryGames from "../components/games/CategoryGames";
import Printable from "../components/printables/printable";
import Printables from "../components/printables";
import CategoryPrintables from "../components/printables/CategoryPrintables";
import Feedback from "../components/feedback";
import Grade from "../components/grades";
import Manage from "../components/manage/index";
import FAQ from "../components/faq";
import Tools from "../components/tools";
import Password from "../components/forgot/password";
import Forgot from "../components/forgot";
import Home from "../components/home";
import CompleteSignup from "../components/subscribe/completeSignup";
import Subscribe from "../components/subscribe";
import Payterms from "../components/payterms";
import Privacy from "../components/privacy";
import Terms from "../components/terms";
import Verify from "../components/verify";
import ProductOfferings from "../components/productOfferings";
import NotFound from "../components/global/notFound";
import Onboarding from "../components/onboarding";
import ScrollToTop from "../components/global/scrollToTop";
import ClassroomForm from "../components/subscribe/classroomForm";
import FamilyForm from "../components/subscribe/familyForm";
import FreeForm from "../components/subscribe/freeForm";
import SchoolForm from "../components/subscribe/SchoolForm";
import { isLoggedInWithoutRole, isLoggedInWithRole } from "../utilities";
import { getLangCode, language, userRoles } from ".";
import { isLoggedInSelector } from "../redux/slices/loginSlice";
import StripeSchoolForm from "../components/subscribe/StripeSchoolForm";
import routeCodes from "./routeCodes";
import faqStrings from "../strings/faq";
import EmailPreferences from "../components/emailPreferences";
import UserType from "../components/onboarding/UserType";
import LoadingAnim from "../components/global/loadingAnim";

export { routeCodes };

const PrivacyMobile = lazy(() => import("../components/privacy/mobile"));
const Standards = lazy(() => import("../components/standards"));
const StandardsStandard = lazy(() => import("../components/standards/standardsStandard"));
const StandardsGame = lazy(() => import("../components/standards/standardsGame"));
const StandardsPrintable = lazy(() => import("../components/standards/standardsPrintable"));

const ManageNoStudents = () => {
  return isLoggedInWithoutRole(userRoles.student) ? (
    <ScrollToTop>
      <Manage />
    </ScrollToTop>
  ) : (
    <Navigate to={routeCodes.HOME} replace />
  );
};

const NoMobileGamesOnDesktop = () => {
  const { shortname } = useParams();

  return !mobile ? <Navigate to={`${routeCodes.GAMES}${shortname}`} replace /> : <MobileGame shortname={shortname} />;
};

const SubAccessOnly = ({ children, to = routeCodes.HOME, roles = [] }) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const hasAccess = roles.length > 0 ? roles.some((role) => isLoggedInWithRole(role)) : isLoggedIn;

  return hasAccess ? <ScrollToTop>{children}</ScrollToTop> : <Navigate to={to} replace />;
};

SubAccessOnly.propTypes = {
  children: PropTypes.object,
  to: PropTypes.string,
  roles: PropTypes.array,
};

const LoggedOutAccessOnly = ({ children }) => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  return isLoggedIn ? <Navigate to={routeCodes.HOME} replace /> : <ScrollToTop>{children}</ScrollToTop>;
};

LoggedOutAccessOnly.propTypes = {
  children: PropTypes.object,
};

const renderDefaultRoutes = (routes) => {
  return getLangCode() === language.default ? routes : null;
};

export default function Routes() {
  return (
    <Suspense fallback={<LoadingAnim className="background-white" position="absolute" />}>
      <DomRoutes>
        {renderDefaultRoutes(
          <>
            <Route
              path={routeCodes.STANDARDS}
              element={
                <ScrollToTop>
                  <Standards />
                </ScrollToTop>
              }
            >
              <Route index element={<StandardsStandard />} />
              <Route path={routeCodes.STANDARDS} element={<StandardsStandard />} />
              <Route path={routeCodes.STANDARDS_GAMES} element={<StandardsGame />} />
              <Route path={routeCodes.STANDARDS_PRINTABLES} element={<StandardsPrintable />} />
            </Route>

            <Route path={`${routeCodes.STANDARDS}*`} element={<NotFound />} />
          </>,
        )}

        <Route
          path={`${routeCodes.COMPLETESIGNUP_STUDENT}/`}
          element={
            <ScrollToTop>
              <CompleteSignup isStudent={true} />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.COMPLETESIGNUP}
          element={
            <ScrollToTop>
              <CompleteSignup />
            </ScrollToTop>
          }
        />

        <Route
          path={`${routeCodes.GRADES}:url`}
          element={
            <ScrollToTop>
              <Grade />
            </ScrollToTop>
          }
        />

        <Route
          path={`${routeCodes.GRADES}:url/:cat`}
          element={
            <ScrollToTop>
              <Grade />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.SUBSCRIBE}
          element={
            <ScrollToTop>
              <Subscribe />
            </ScrollToTop>
          }
        >
          <Route index element={<FamilyForm />} />
          <Route path={routeCodes.SUBSCRIBE_FAMILY} element={<FamilyForm />} />
          <Route path={routeCodes.SUBSCRIBE_FREE} element={<FreeForm />} />
          <Route path={routeCodes.SUBSCRIBE_CLASSROOM} element={<ClassroomForm />} />
        </Route>

        {renderDefaultRoutes(
          <>
            <Route
              path={`${routeCodes.SUBSCRIBE_SCHOOL}`}
              element={
                <ScrollToTop>
                  <SchoolForm />
                </ScrollToTop>
              }
            />

            <Route
              path={`${routeCodes.SUBSCRIBE_ABCYA_SCHOOL}`}
              element={
                <ScrollToTop>
                  <StripeSchoolForm />
                </ScrollToTop>
              }
            />
          </>,
        )}

        <Route path={`${routeCodes.SUBSCRIBE}*`} element={<NotFound />} />

        <Route
          path={`${routeCodes.VERIFY}:id`}
          element={
            <ScrollToTop>
              <Verify />
            </ScrollToTop>
          }
        />

        <Route
          path={`${routeCodes.CATEGORYGAMES}:cat`}
          element={
            <ScrollToTop>
              <CategoryGames />
            </ScrollToTop>
          }
        />

        <Route path={`${routeCodes.MOBILEGAMES}:shortname`} element={<NoMobileGamesOnDesktop />} />

        <Route path={`${routeCodes.GAMEPROXY}:shortname`} element={<GameProxy />} />

        <Route
          path={`${routeCodes.GAMES}:shortname`}
          element={
            <ScrollToTop>
              <Game />
            </ScrollToTop>
          }
        />

        <Route
          path={`${routeCodes.PRINTABLES}:shortname`}
          element={
            <ScrollToTop>
              <SubAccessOnly to={routeCodes.PRINTABLES}>
                <Printable />
              </SubAccessOnly>
            </ScrollToTop>
          }
        />

        {renderDefaultRoutes(
          <>
            <Route
              path={`${routeCodes.BLOG}:id`}
              element={
                <ScrollToTop>
                  <BlogPost />
                </ScrollToTop>
              }
            />
            <Route
              path={routeCodes.BLOG}
              element={
                <ScrollToTop>
                  <Blog />
                </ScrollToTop>
              }
            />
          </>,
        )}

        <Route path={`${routeCodes.CATEGORYPRINTABLES}:shortname`} element={<CategoryPrintables />} />

        <Route path={`${routeCodes.CATEGORYPRINTABLES}:shortname/:cat`} element={<CategoryPrintables />} />

        <Route
          path={routeCodes.FEEDBACK}
          element={
            <ScrollToTop>
              <Feedback />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.REDEEMCARD}
          element={
            <ScrollToTop>
              <Card />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.REDEEM}
          element={
            <ScrollToTop>
              <Redeem />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.GIFTCARD}
          element={
            <ScrollToTop>
              <Giftcard />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.TESTIMONIALS}
          element={
            <ScrollToTop>
              <Testimonals />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.TEAM}
          element={
            <ScrollToTop>
              <Team />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.STORY}
          element={
            <ScrollToTop>
              <Story />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.ABOUT}
          element={
            <ScrollToTop>
              <About />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.PARTEA}
          element={
            <ScrollToTop>
              <Partea />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.PRINTABLES}
          element={
            <ScrollToTop>
              <Printables />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.CONTACT}
          element={
            <Navigate
              to={{
                pathname: routeCodes.FAQ,
                hash: faqStrings.sections[0].title.toLowerCase(),
              }}
              state={{ activeId: "general_contactus" }}
              replace
            />
          }
        />

        <Route
          path={routeCodes.TRYMOBILE}
          element={
            <Navigate
              to={{
                pathname: routeCodes.FAQ,
                hash: faqStrings.sections[4].title.toLowerCase(),
                // trymobile should be first index (0) so we aren't always
                // having to change it in faqStrings '/strings/faq'
              }}
              state={{ activeId: "apps0" }}
              replace
            />
          }
        />

        <Route
          path={routeCodes.FAQ}
          element={
            <ScrollToTop>
              <FAQ />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.TOOLS}
          element={
            <SubAccessOnly>
              <Tools />
            </SubAccessOnly>
          }
        />

        <Route path={routeCodes.MANAGE} element={<ManageNoStudents />} />

        <Route
          path={routeCodes.UPDATEPASSWORD}
          element={
            <LoggedOutAccessOnly>
              <Password />
            </LoggedOutAccessOnly>
          }
        />

        <Route
          path={routeCodes.FORGOT}
          element={
            <LoggedOutAccessOnly>
              <Forgot />
            </LoggedOutAccessOnly>
          }
        />

        <Route
          path={routeCodes.GAMES}
          element={
            <ScrollToTop>
              <Games />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.SEARCH}
          element={
            <ScrollToTop>
              <Search />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.PAYTERMS}
          element={
            <ScrollToTop>
              <Payterms />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.PRIVACY}
          element={
            <ScrollToTop>
              <Privacy />
            </ScrollToTop>
          }
        />

        {renderDefaultRoutes(
          <Route
            path={routeCodes.PRIVACYMOBILE}
            element={
              <ScrollToTop>
                <PrivacyMobile />
              </ScrollToTop>
            }
          />,
        )}

        <Route
          path={routeCodes.PRODUCTOFFERINGS}
          element={
            <ScrollToTop>
              <ProductOfferings />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.TERMS}
          element={
            <ScrollToTop>
              <Terms />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.LOGIN}
          element={
            <ScrollToTop>
              <Home shouldLogin={true} />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.HOME}
          element={
            <ScrollToTop>
              <Home />
            </ScrollToTop>
          }
        />

        <Route
          path={routeCodes.EMAIL_PREFERENCES}
          element={
            <ScrollToTop>
              <EmailPreferences />
            </ScrollToTop>
          }
        />

        <Route
          path={`${routeCodes.EMAIL_PREFERENCES}:subscriberKey`}
          element={
            <ScrollToTop>
              <EmailPreferences />
            </ScrollToTop>
          }
        />

        <Route
          path={`${routeCodes.ONBOARDING}`}
          element={
            <SubAccessOnly
              roles={[userRoles.free]}
              to={isLoggedInWithoutRole(userRoles.free) ? routeCodes.HOME : routeCodes.SUBSCRIBE}
            >
              <Onboarding />
            </SubAccessOnly>
          }
        />

        <Route
          path={`${routeCodes.ONBOARDING_FAMILY}`}
          element={
            <SubAccessOnly roles={[userRoles.free]}>
              <UserType userType="family" />
            </SubAccessOnly>
          }
        />

        <Route
          path={`${routeCodes.ONBOARDING_TEACHER}`}
          element={
            <SubAccessOnly roles={[userRoles.free]}>
              <UserType userType="teacher" />
            </SubAccessOnly>
          }
        />

        <Route path="*" element={<NotFound />} />
      </DomRoutes>
    </Suspense>
  );
}
